import React from "react";
import { Link, graphql } from "gatsby";
import Helmet from "react-helmet";
import { Layout } from "../layouts/layout";

const ErrorPage = ({ data }) => (
    <Layout data={data}>
        <ErrorPageBody></ErrorPageBody>
    </Layout>
);

const ErrorPageBody = ({ radioValue }) => {
    return (
        <div>
            <Helmet title={"404"} />
            <div className="post">
                <div>
                    <h2>Hey there,</h2>
                    <p>This page does not exist or is no longer reachable.</p>
                    <p>
                        Maybe try returning to the <Link to="/">homepage</Link>?
                    </p>
                </div>
            </div>
        </div>
    );
};

export default ErrorPage;

export const query = graphql`
    query {
        site {
            siteMetadata {
                title
                description
            }
        }
    }
`;
